import axios from '../lib/';
import useAuth from './useAuth';

const useRefreshToken = () => {
    // const { setAuth } = useAuth();
    const { auth, setAccessToken, setExp } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/users', {
            withCredentials: true
        });
        let accessToken = response.data.accessToken;
        // setAuth(prev => {
        //     console.log(JSON.stringify(prev));
        //     console.log(response.data.accessToken);
        //     return { ...prev, accessToken: response.data.accessToken }
        // });
        setAccessToken(accessToken);
        setExp(JSON.parse(window.atob(accessToken.split(".")[1])).exp);
        return accessToken;
    }
    return refresh;
};

export default useRefreshToken;