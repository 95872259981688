import React from "react";
import "./styles/admin.css"
import StatisticsWrapper from "../StatisticsWrapper/StatisticsWrapper";
import ReservationSearchWrapper from "../ReservationSearchWraper/ReservationSearchWrapper";
import CursorPaginationGrid from "../ShowReservations/ShowReservations"
import SettingsWrapper from "../SettingsWrapper/SettingsWrapper";
const AdminPage = ()=>{

    return(
        <div className="admin-page-wrapper">
            <SettingsWrapper></SettingsWrapper>
            <StatisticsWrapper></StatisticsWrapper>
            <ReservationSearchWrapper></ReservationSearchWrapper>
            <CursorPaginationGrid></CursorPaginationGrid>
        </div>
    )
}
export default AdminPage;