import axios from 'axios';
// const BASE_URL = 'https://localhost:3000';
const BASE_URL = '';

export default axios.create({
    baseURL: BASE_URL,
    withCredentials:true,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

export const formatDateToString = function(date){
    console.log(date);
    const { $y: year, $M:month, $D: day, $H: hour, $m: minutes} = date;
    let M = doubleDigitDate(month+1)
    let d = doubleDigitDate(day)
    let h = doubleDigitDate(hour)
    let m = doubleDigitDate(minutes)
    let dateString = `${d}-${M}-${year} ${h}:${m}`;
    return dateString
}

const doubleDigitDate = (value) => {
    return value < 10 ? `0${value}` : value
} 