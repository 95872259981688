import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    date:'',
    string:''
}

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setString: (state, action) => {
            state.string = action.payload;
        }
    }
})

export const { setDate,setString  } = searchSlice.actions
export default  searchSlice.reducer