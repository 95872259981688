import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@mui/system";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Typography, IconButton, Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { formatDateToString, axiosPrivate } from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { setFetchData } from "../store/fetchDataSlice";
import Grid from "@mui/material/Grid";

const CustomModal = ({ open, handleClose }) => {
  const [value, setValue] = useState(dayjs());
  const outerTheme = useTheme();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const dispatch = useDispatch();
  const initialFields = {
    imePrezime: "",
    mail: "",
    pocetniDatum: dayjs(),
    krajnjiDatum: dayjs(),
    telefon: "",
    brOsoba: "",
    tablice: "",
    cena: "",
    status: "Čekanje",
    tipParkinga:"natkriveni",
    grad: "",
    napomena: "",
  };

  const [fields, setFields] = useState(initialFields);

  return (
    <div>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
        <Box sx={[{ width: '100%', padding: '20px' },style]} >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 0.5 }}>
        <Grid xs={12}>
          <Typography
            variant="h4"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // color: 'White',
            }}
          >
            Dodavanje rezervacije
          </Typography>
        </Grid>

        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Ime i prezime"
            variant="standard"
            value={fields.imePrezime}
            onChange={(event) => {
              setFields({
                ...fields,
                imePrezime: event.target.value,
              });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Telefon"
            variant="standard"
            value={fields.telefon}
            onChange={(event) => {
              setFields({ ...fields, telefon: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>

        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={fields.pocetniDatum}
              onChange={(value) => {
                setFields({ ...fields, pocetniDatum: value });
              }}
              label="Datum i vreme dolaska"
              format="DD/MM/YYYY HH:MM"
              sx={{ width: '250px' }}
              ampm={false}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={fields.krajnjiDatum}
              onChange={(value) => {
                setFields({ ...fields, krajnjiDatum: value });
              }}
              label="Datum i vreme odlaska"
              format="DD/MM/YYYY HH:MM"
              sx={{ width: '250px' }}
              ampm={false}
            />
          </LocalizationProvider>
        </Grid>

        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Mejl adresa"
            variant="standard"
            value={fields.mail}
            onChange={(event) => {
              setFields({ ...fields, mail: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Broj osoba za prevoz"
            variant="standard"
            value={fields.brOsoba}
            onChange={(event) => {
              setFields({ ...fields, brOsoba: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
            type="number"
          />
        </Grid>

        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Tablice"
            variant="standard"
            value={fields.tablice}
            onChange={(event) => {
              setFields({ ...fields, tablice: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Cena"
            variant="standard"
            value={fields.cena}
            onChange={(event) => {
              setFields({ ...fields, cena: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>
        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <FormControlLabel
            control={<Checkbox
              onChange={(event) => {
                setFields({ ...fields, tipParkinga: event.target.checked ? "otkriveni" : "natkriveni"});
              }}
            />}
            label="Otkriveni parking"
          />
          
        </Grid>
        <Grid xs={12} sm={6} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Grad"
            variant="standard"
            value={fields.grad}
            onChange={(event) => {
              setFields({ ...fields, grad: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>
        <Grid xs={12} item sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
          <TextField
            label="Napomena"
            variant="standard"
            multiline
            rows={5}
            value={fields.napomena}
            onChange={(event) => {
              setFields({ ...fields, napomena: event.target.value });
            }}
            sx={{
              color: 'black',
              width: '250px',
            }}
          />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ width: "100px" }}
                >
                  Otkazi
                </Button>
        </Grid>

        <Grid
          xs={12}
          sm={6}
          item
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
                <Button
                  variant="contained"
                  onClick={async () => {
                    const data = {
                      ...fields,
                      krajnjiDatum: formatDateToString(fields.krajnjiDatum),
                      pocetniDatum: formatDateToString(fields.pocetniDatum),
                    };
                    const cleanObject = (obj) => {
                      for (let key in obj) {
                        if (obj[key] === '') {
                          delete obj[key];
                        }
                      }
                      return obj;
                    };
                    try {
                      let response = await axiosPrivate.post(
                        "/reservations",
                        cleanObject(data)
                      );
                      if (response.status === 200) {
                        dispatch(setFetchData(true));
                        handleClose();
                      } else {
                        throw new Error("Nije status 200");
                      }
                    } catch (error) {
                      console.log(error);
                      alert("Doslo je do greske!");
                    }
                  }}
                  sx={{ width: "100px" }}
                >
                  Dodaj
                </Button>
        </Grid>
      </Grid>
    </Box>
        </Fade>
      </StyledModal>
    </div>
  );
};

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "600px",
  height:"fit-content",
  width: "100%",
  maxWidth: "700px",
  backgroundColor: "#FFF",
  border: "2px solid currentColor",
  borderRadius: "30px",
  boxShadow: 24,
  padding: "32px 64px",
});

const boxStyle = (theme) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap:"wrap",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "5px",
  padding: "0 40px 0 40px",
});

export default CustomModal;
