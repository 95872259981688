import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reservationStatusFilters: [],
}

export const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setReservationStatusFilters:(state,action) => {
            state.reservationStatusFilters = action.payload;
        }
    }
})

export const { setReservationStatusFilters } = filterSlice.actions
export default  filterSlice.reducer