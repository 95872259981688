import "./styles/showReservations.css";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { formatDateToString, axiosPrivate } from '../lib';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import dayjs from 'dayjs';

import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';

import {
  GridRowModes,
  DataGrid,
  // GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { setDataGridFetch } from '../store/fetchDataSlice';


const darkTheme = createTheme({ palette: { mode: 'dark' } });
const lightTheme = createTheme({ palette: { mode: 'light' } });
const PAGE_SIZE = 60;


export default function FullFeaturedCrudGrid() {
  const axiosPrivate = useAxiosPrivate();
  const userRole = useSelector((state) => state.user.role)
  const dispatch = useDispatch();
  const selectedStatusFilters = useSelector((state) => state.filters.reservationStatusFilters)
  const columns = [
    // {
    //     field: "id",
    //     headerName:"id",
    //     editable:false,
    //     hide: true
    // },
    {
      field: 'rowNumber',
      headerName: 'R. br.',
      width: 100,
      editable:false,
      sortable:false,
      renderCell: (params) => {
        return (
          <>
            {params.value} {/* This is the row number */}
            {params.row.napomena && <WarningIcon style={{ color: 'red' }} onClick={() => alert(params.row.napomena)} />}
          </>
        );
      },
    },
    {
        field:"imePrezime",
        headerName:"Ime i Preizme",
        editable: userRole === "admin" ? true : false,
        width:150,
        sortable:false,
    },
    {
        field:"mail",
        headerName:"E-mail",
        editable: userRole === "admin" ? true : false,
        width:150,
        sortable:false,
    },
    {
        field:"telefon",
        headerName:"Telefon",
        editable: userRole === "admin" ? true : false,
        width:150,
        sortable:false,
    },
    {
        field:"pocetniDatum",
        headerName:"Datum i vreme dolaska",
        editable: userRole === "admin" ? true : false,
        width:200,
        sortable:false,
        type:'dateTime',
    },
    {
        field:"krajnjiDatum",
        headerName:"Datum i vreme odlaska",
        editable: userRole === "admin" ? true : false,
        width:200,
        sortable:false,
        type:'dateTime',
    },
    {
        field:"tablice",
        headerName:"Tablice",
        editable: userRole === "admin" ? true : true,
        width:150,
        sortable:false,
    },
    {
        field:"brOsoba",
        headerName:"Broj osoba",
        editable: userRole === "admin" ? true : false,
        width:150,
        sortable:false,
    },
    {
        field:"cena",
        headerName:"Cena",
        editable: userRole === "admin" ? true : false,
        width:150,
        sortable:false,
    },
    {
      field:"grad",
      headerName:"Putuje u grad",
      editable: userRole === "admin" ? true : false,
      width:150,
      sortable:false,
    },
    {
      field:"napomena",
      headerName:"Napomena",
      editable: userRole === "admin" ? true : true,
      width:150,
      sortable:false,
    },
    {
      field:"tipParkinga",
      headerName:"Parking",
      editable: userRole === "admin" ? true : false,
      type: "singleSelect",
      valueOptions:[
        'natkriveni',
        'otkriveni',
      ],
      width:150,
      sortable:false,
    },
    {
        field:"status",
        headerName:"Status",
        editable: true,
        type: "singleSelect",
        valueOptions:[
          'Čekanje',
          'Parkiran',
          'Otkazana',
          'Završena',
        ],
        width:150,
        sortable:false,
    },
    {
      field:"lokacija",
      headerName:"Lokacija",
      editable: true,
      type: "singleSelect",
      valueOptions:[
        'Hala 1',
        'Hala 2',
        'Hala 3',
        'Hala 4',
        'Otvoreni deo',
      ],
      width:150,
      sortable:false,
    },
    {
      field:"kljuc",
      headerName:"Ostavljen kljuc",
      editable: true,
      type: "singleSelect",
      valueOptions:[
        'Da',
        'Ne',
      ],
      width:150,
      sortable:false,
    },
  ]

    columns.push(
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Opcije',
        width: 150,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color='inherit'
              />,
            ];
          }
          if(userRole === "admin"){
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                // className="textPrimary"
                onClick={handleEditClick(id)}
                sx={{
                  color: 'primary.main',
                }}
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color='inherit'
              />,
            ];
          }else{
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                // className="textPrimary"
                onClick={handleEditClick(id)}
                sx={{
                  color: 'primary.main',
                }}
              />]
          } 
        },
      }
    )

  const [rows, setRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const fetchData = async (page, string, date) => {
    setIsLoading(true);
    // Make an API request to your server with page and pageSize parameters
    try{
      const params = {index:page, string:string != undefined ? string : "", date:date != undefined ? date : ""};
      const queryString = new URLSearchParams(params).toString();
      const response = await axiosPrivate.get(`/reservations/all?${queryString}`);
      let rows = response.data.reservations.map(({ _id, ...rest }) => ({ id: _id, ...rest }));
      const newArray = rows.map(obj => {
        // Extract the datetime string from the object
        const { pocetniDatum, krajnjiDatum, ...rest } = obj;
      
        // Split the date and time components
        const [datePart, timePart] = pocetniDatum.split(' ');
        const [datePart2, timePart2] = krajnjiDatum.split(' ');
      
        // Split the date into day, month, and year
        const [day, month, year] = datePart.split('-');
        const [day2, month2, year2] = datePart2.split('-');
  
      
        // Split the time into hours and minutes
        const [hours, minutes] = timePart.split(':');
        const [hours2, minutes2] = timePart2.split(':');
      
        // Create a new Date object from the parsed components
        const dateObject = new Date(year, month - 1, day, hours, minutes);
        const dateObject2 = new Date(year2, month2 - 1, day2, hours2, minutes2);
      
        // Return a new object with the datetime field replaced by a Date object
        return {
          ...rest,
          pocetniDatum: dateObject,
          krajnjiDatum: dateObject2
        };
      });
      const rowsWithNumbers = newArray.map((row, index) => ({
        ...row,
        rowNumber: index + 1 + paginationModel.page * PAGE_SIZE,
      }));
      setRows(rowsWithNumbers);
      // setTotalRows(response.data.totalPages);
      setTotalItems(response.data.totalItems)
    }catch(err){
      console.log(err)
    }finally{
      setIsLoading(false);
    }
  };

  const searchString = useSelector((state) => state.search.string);
  const searchDate = useSelector((state) => state.search.date);
  useEffect(() => {
    fetchData(paginationModel.page + 1, searchString, searchDate);
  }, [paginationModel]);

  const signal = useSelector((state) => state.fetch.dataGridFetch);
  useEffect(()=>{
    if(signal){
      setPaginationModel({    page: 0,
        pageSize: PAGE_SIZE,})
      fetchData(paginationModel.page + 1, searchString, searchDate);
      dispatch(setDataGridFetch(false))
    }
  },[signal])


  useEffect(()=>{
    
      setPaginationModel({    page: 0,
        pageSize: PAGE_SIZE,})
      fetchData(paginationModel.page + 1, searchString, searchDate);
   
  },[searchDate, searchString])

  const filterByStatus = () => {
    const data = 
    selectedStatusFilters
.length > 0
    ? rows.filter((row) => selectedStatusFilters
.includes(row.status))
    : rows;
    
    return data
  }

  const [rowCountState, setRowCountState] = useState(totalItems);
  useEffect(() => {
  setRowCountState((prevRowCountState) =>
    totalItems !== undefined ? totalItems : prevRowCountState,
  );
}, [totalItems, setRowCountState]);


    const [rowModesModel, setRowModesModel] = React.useState({});

  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     event.defaultMuiPrevented = true;
  //   }
  // };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const [editedRow, setEditedRow] = useState(null);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    axiosPrivate.delete(`/reservations/${id}`)
    .then(setRows(rows.filter((row) => row.id !== id)))
    .catch((err)=>{console.log(err)})
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    const stringifiedRow = {
      ...updatedRow,
      pocetniDatum : updatedRow.pocetniDatum.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).replace(",", "").replace(".","-").replace(".","-"),
      krajnjiDatum : updatedRow.krajnjiDatum.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).replace(",", "").replace(".","-").replace(".","-"),
    }
    console.log(stringifiedRow.pocetniDatum)
    axiosPrivate.put(`/reservations/`, stringifiedRow)
    .then()
    .catch((err)=>{console.log(err)})
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  return (
    <ThemeProvider theme={lightTheme}>
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={filterByStatus()}
        columns={columns}
        // editMode={userRole === "admin" ? "row" : "none"}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        getRowId={(row)=>row.id}
        disableColumnMenu={true}
        page={paginationModel.page}
        rowCount={rowCountState}
        pageSizeOptions={[PAGE_SIZE]}
        paginationMode="server"
        loading={isLoading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
   </ThemeProvider>
  );
}

