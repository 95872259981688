import React from 'react'
import "./styles/WorkerPage.css"
import ReservationSearchWrapper from "../ReservationSearchWraper/ReservationSearchWrapper";
import CursorPaginationGrid from "../ShowReservations/ShowReservations"
import SettingsWrapper from "../SettingsWrapper/SettingsWrapper";



const WorkerPage = () => {
  return (
    <div className="worker-page-wrapper">
        <SettingsWrapper></SettingsWrapper>
        <ReservationSearchWrapper></ReservationSearchWrapper>
        <CursorPaginationGrid></CursorPaginationGrid>
    </div>
  )
}

export default WorkerPage