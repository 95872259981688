import React from 'react'
import "./styles/settingsWrapper.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch} from 'react-redux';
import { useNavigate } from "react-router-dom"
import { logOut } from "../store/userSlice";




const darkTheme = createTheme({ palette: { mode: 'dark' } });


const SettingsWrapper = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function handleClick(){
        dispatch(logOut());
        navigate("/")
    }
  return (
    // <ThemeProvider theme={darkTheme}>
    <Box
      sx={{
        p: 2,
        bgcolor: 'background.default',
        display: 'flex',
        flexWrap:'wrap',
        justifyContent: 'end',
        alignItems:'center'
      }}
    >
      <IconButton aria-label="logout" onClick={handleClick}>
        <LogoutIcon sx={{color:'primary.main'}}/>
      </IconButton>
    </Box>
  // </ThemeProvider>
  )
}

export default SettingsWrapper