import React,{ useState }from 'react'
import "./styles/reservationSearchWrapper.css"
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import MultipleSelectCheckmarks from '../DropdownMenu/DropdownMenu'
import Button from '@mui/material/Button';
import CustomModal from '../CustomModal/CustomModal';
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setString, setDate } from  '../store/searchSlice';
import { formatDateToString } from '../lib';


const darkTheme = createTheme({ palette: { mode: 'dark' } });

const ReservationSearchWrapper = () => {

  const userRole = useSelector((state) => state.user.role)

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchDate, setSearchDate] = useState();
  const delay = 100;

  const handleClick = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, delay));
    setLoading(false);
    handleOpen();
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    dispatch(setString(searchString))
  }
  const handleDateChange = (value)=>{
    let date = formatDateToString(value)
    date = date.substring(0, 10)
    dispatch(setDate(date))
  }
  return (
    // <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.default',
          display: 'flex',
          flexWrap:'wrap',
          justifyContent: 'space-around',
          alignItems:'center',
          borderTop: 1,
          borderColor: 'text.primary'
        }}
      >
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          // value={searchString}
        >
          <TextField id="outlined-basic" label="Pretraži..." variant="outlined" value={searchString} onChange={(e)=>{setSearchString(e.target.value)}}/>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker value={searchDate} onChange={handleDateChange} clearable></DatePicker>
        </LocalizationProvider>
        <MultipleSelectCheckmarks></MultipleSelectCheckmarks>
        <Button variant="contained" onClick={handleClick}>Dodaj rezervaciju</Button>
        {loading && (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
        {open && <CustomModal open={open} handleClose={handleClose} />} 
      </Box>
    // </ThemeProvider>
  )

}

const Loader = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default ReservationSearchWrapper