import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken:'',
  role:'worker',
  exp: 0,
  reservationStatusFilters: [],
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
        setExp: (state, action) => {
            state.exp = action.payload;
        },
        logOut: (state, action) => {
            state.accessToken = null;
            state.role = null;
            state.exp = null;
        },
        setReservationStatusFilters:(state,action) => {
            state.reservationStatusFilters = action.payload;
        }
    }
})

export const { setAccessToken,setRole, setExp, logOut  } = userSlice.actions
export default  userSlice.reducer