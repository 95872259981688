import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataGridFetch: false,
  statisticsFetch: false
}

export const fetchSlice = createSlice({
    name: "fetch",
    initialState,
    reducers: {
        setFetchData:(state,action) => {
            state.dataGridFetch = action.payload;
            state.statisticsFetch = action.payload;
        },
        setDataGridFetch:(state, action) =>{
            state.dataGridFetch = action.payload;
        },
        setStatisticsFetch:(state, action) =>{
            state.statisticsFetch = action.payload;
        }
    }
})

export const { setDataGridFetch, setStatisticsFetch, setFetchData } = fetchSlice.actions
export default  fetchSlice.reducer