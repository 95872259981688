import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import "./styles/statisticsWrapper.css";
import Statistic from '../Statistic/Statistic';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import {setStatisticsFetch} from "../store/fetchDataSlice";



const darkTheme = createTheme({ palette: { mode: 'dark' } });


const StatisticsWrapper = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [date, setDate] = useState(dayjs());
  const [data, setData] = useState({});
  const [value, setValue] = useState(date)
  const { trenutniBrojVozila, numberOfTodaysReservations, valueOfTodaysReservations, numberOfYesterdaysReservations,  valueOfYesterdaysReservations,
          allVehicles, naplacenaKolicina, nenaplacenaKolicina, zavrseno, valueOfAllReservationsEndingToday, reservationsBegginingThisMonth } = data;
  
  let zarada = {naplaceno: naplacenaKolicina, nenaplaceno: nenaplacenaKolicina};
  let prosecnaCena = parseFloat((naplacenaKolicina / zavrseno).toFixed(2));

  useEffect(() => { 
    let isMounted = true;
    const controller = new AbortController();

    const getData = async () => {
      let { $y:year, $M:month} = value;
      month += 1;

        try {
            const response = await axiosPrivate.get(`/reservations/statistics/${year}/${month}`, {
                signal: controller.signal
            });
            isMounted && setData(response.data);
        } catch (err) {
            console.error(err);
            // navigate('/login', { state: { from: location }, replace: true });
        }
    }

    getData();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }, [value]);
  const signal = useSelector((state) => state.fetch.statisticsFetch);
  useEffect(()=>{
    if(signal){
      const getData = async () => {
        let { $y:year, $M:month} = value;
        month += 1;
  
          try {
              const response = await axiosPrivate.get(`/reservations/statistics/${year}/${month}`);
              setData(response.data)
          } catch (err) {
              console.error(err);
          }
      }
  
      getData();
      dispatch(setStatisticsFetch(false))
    }
  },[signal])
  return (
    // <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          p: 2,
          bgcolor: 'background.default',
          display: 'flex',
          flexWrap:'wrap',
          justifyContent: 'space-around',
          alignItems:'center'
          // gridTemplateColumns: { md: '1fr 1fr' },
          // gap: 2,
        }}
      >
        <Statistic value={trenutniBrojVozila} type={"parking"} description={"Br. vozila na parkingu"}/>
        <Statistic value= {numberOfTodaysReservations} type={"reservations"} description={"Br. danasnjih rezervacija"}/>
        <Statistic value= {valueOfTodaysReservations} type={"value"} description={"Vrednost danasnjih rezervacija"}/>
        <Statistic value= {valueOfAllReservationsEndingToday} type={"value"} description={"Vrednost rezervacija koje se danas zavrsavaju"}/>
        <Statistic value= {numberOfYesterdaysReservations} type={"reservations"} description={"Br. jucerasnjih rezervacija"}/>
        <Statistic value= {valueOfYesterdaysReservations} type={"value"} description={"Vrednost jucerasnjih rezervacija"}/>
        <Statistic value={zarada} type={"earnings"} description={"Naplaceno i nenaplaceno za mesec"}/>
        <Statistic value={allVehicles} type={"cars"} description={"Br. vozila koja su bila na parkingu za mesec"}/>
        <Statistic value={zavrseno} type={"completed"} description={"Br. zavrsenih rezervacija"}/>
        <Statistic value={prosecnaCena} type={"average"} description={"Prosecna cena zavrsene rezervacije"}/>
        <Statistic value={reservationsBegginingThisMonth} type={"month"} description={"Broj rezervacija koje pocinju ovog meseca i njihova vrednost"}/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker value={value} onChange={setValue} views={['month', 'year']} />
        </LocalizationProvider>
      </Box>
    // </ThemeProvider>
          
          
        
  )
}

export default StatisticsWrapper