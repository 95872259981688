import React, { useState } from "react";
import axios from "../lib";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken, setExp, setRole } from "../store/userSlice";
import { useNavigate } from "react-router-dom"
import { Box, Grid, Typography, TextField, Button } from "@mui/material";



import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({ palette: { mode: 'dark' } });


const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (username && password) {
      axios
        .post('/users', {
          username: username,
          password: password,
        })
        .then((res) => {
          if (res.status === 200) {
            let accessToken = res.data.accessToken;
            let payload = JSON.parse(window.atob(accessToken.split(".")[1]));
            dispatch(setExp(payload.exp));
            dispatch(setRole(payload.role));
            dispatch(setAccessToken(accessToken));
            if(payload.role === "admin"){
              navigate("/admin");
            }else{
              navigate("/worker");
            }
          } else {
            throw new Error("Nije uspesno logovanje");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={e=>{handleLogin(e)}} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  </ThemeProvider>
  );
};

export default Login;
