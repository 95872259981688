
import "./App.css";
import Login from "./Login/Login"
import AdminPage from "./AdminPage/AdminPage";
import WorkerPage from "./WorkerPage/WorkerPage";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  direction: 'rtl',
  // other theme properties
});
function App() {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/worker" element={<WorkerPage />} />
      </Routes>
      </ThemeProvider>

    </div>
  );
}

export default App;
