import React from 'react'
import "./styles/Statistic.css"
import LocalParkingOutlinedIcon from '@mui/icons-material/LocalParkingOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  width: "fit-content",
  minWidth:60,
  lineHeight: '60px',
  margin:10
}));
const darkTheme = createTheme({ palette: { mode: 'dark' } });

const Statistic = ({value, type, description}) => {

  let variant = null;
  let icon = null;
  let elevation = 0;
  switch (type){
    case "parking":
      icon = <LocalParkingOutlinedIcon/>;
      elevation = 0;
      variant="outlined";
      break;
    case "reservations":
      icon = <BookOnlineOutlinedIcon/>;
      elevation = 2;
      variant="elevation";
      break;
    case "value":
      icon = <EuroOutlinedIcon/>;
      elevation = 2;
      variant="elevation";
      break;
    case "earnings":
      icon = <EuroOutlinedIcon/>;
      elevation = 4;
      variant="elevation";
      break;
    case "cars":
      icon = <DirectionsCarFilledOutlinedIcon/>;
      elevation = 6;
      variant="elevation";
      break;
    case "completed":
      icon = <DoneAllOutlinedIcon/>;
      elevation = 8;
      variant="elevation";
      break;
    case "average":
      icon = <EuroOutlinedIcon/>;
      elevation = 10;
      variant="elevation";
      break;
    default:
      icon = null;
  }
  if(type === "month"){
    icon = <EuroOutlinedIcon/>
    elevation = 10;
    variant="elevation";
  }
  let htmlValue = "NaN";
  let htmlValue2;
  if(value != null){
    if(type === "month"){
      htmlValue = value.length;
      let sum = 0;
      for(const reservation of value){
        sum += Number.parseFloat(reservation.cena);
      }
      htmlValue2 = sum;
    } else if(typeof(value) === "number"){
      htmlValue = value
    }else if(typeof(value) === "object"){
      htmlValue = `${value.naplaceno}RSD/`;
      htmlValue2 = `${value.nenaplaceno}RSD`
    }
  }

  return (
      // <ThemeProvider theme={darkTheme}>
          <div className='statistic'>
            <h2>{description}</h2>
            <Item variant={variant} elevation={elevation}
            sx={{
                p:2
              }}
            >
              <Typography>{icon}</Typography>
              <Typography>{htmlValue}</Typography>
              <Typography>{htmlValue2}</Typography>
            </Item>
          </div>
      // </ThemeProvider>
  )
}

export default Statistic










// import * as React from 'react';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';
// import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   height: 60,
//   lineHeight: '60px',
// }));

// const darkTheme = createTheme({ palette: { mode: 'dark' } });
// const lightTheme = createTheme({ palette: { mode: 'light' } });

// export default function Elevation() {
//   return (
//     <Grid container spacing={2}>
//       {[lightTheme, darkTheme].map((theme, index) => (
//         <Grid item xs={6} key={index}>
//           <ThemeProvider theme={theme}>
//             <Box
//               sx={{
//                 p: 2,
//                 borderRadius: 2,
//                 bgcolor: 'background.default',
//                 display: 'grid',
//                 gridTemplateColumns: { md: '1fr 1fr' },
//                 gap: 2,
//               }}
//             >
//               {[0, 1, 2, 3, 4, 6, 8, 12, 16, 24].map((elevation) => (
//                 <Item key={elevation} elevation={elevation}>
//                   {`elevation=${elevation}`}
//                 </Item>
//               ))}
//             </Box>
//           </ThemeProvider>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }