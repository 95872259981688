import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './userSlice';
import filterReducer from './filterSlice';
import fetchReducer from './fetchDataSlice';
import searchReducer from './searchSlice';

const rootReducer = combineReducers({
  user: userReducer,
  filters: filterReducer,
  fetch: fetchReducer,
  search:searchReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // The slices you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };